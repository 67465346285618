<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:showActionDelete="false"
		tooltipMsg="manualfordictio3324"
	>
		<template #header>
			{{ $t('detailofword') }}
			<label>{{ $t('id') }}&nbsp;{{ model.DtextId }}</label>
		</template>

		<template #formActions>
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('savechanges2578')"
				@handleClick="saveModel({ closeOnSuccess: false })"
			></megau-button>

			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('savechanges')"
				@handleClick="saveModel({ closeOnSuccess: true })"
			></megau-button>

			<megau-button
				classprop="btn btn-success"
				icon="times"
				:title="$t('savechangesandu')"
				@handleClick="saveModelAndRefresh()"
			></megau-button>

			<megau-button
				classprop="btn btn-primary"
				icon="undo"
				:title="$t('cancel2168')"
				@handleClick="redirectToTable()"
			></megau-button>
		</template>
	</detail-page>
</template>
<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceDictionary from '../../services/service/dictionary.service';
import serviceEnums from '@/services/service/enums.service';

import i18n from '@/services/lang';
import api from '../../services/helpers/http';

import { model, fields } from './translation.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'Translation',
			routeParam: 'DtextId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	methods: {
		async loadResources() {
			var groups = await serviceDictionary.getDictionaryTextGroupsEnum();

			this.updateSchemaFields({
				DtgroupId: {
					values: groups.dropdownVfg,
				},
				DtextTypeId: {
					values: serviceEnums.getEnumForVFG('dictionaryTextType'),
				},
				ResetCode: {
					onSubmit: async (model) => {
						await this.resetCode(model);
					},
				},
			});
		},

		async resetCode(model) {
			await this.$modal.show({
				title: i18n.t('resetcode3240'),
				text: i18n.t('doyouwanttorese3241'),
				okTitle: i18n.t('okreset'),
				modalClass: 'modal-danger',
				variant: 'danger',
				onConfirm: async () => {
					var response = await api.get('Translation/ResetCode', { id: model.DtextId });
					this.updateFormModel({ Code: response.data });
				},
			});
		},

		async saveModelAndRefresh() {
			await this.saveModel({ closeOnSuccess: false });

			let resp = await api.get('Translation/ResetCache', {});
			if (resp.data === true) {
				this.$store.dispatch('alert/success', this.$i18n.t('translationsupd'));
			} else {
				this.$store.dispatch('alert/warning', this.$i18n.t('Chyba pri aktualizovani prekladov!'));
			}
		},
	},
};
</script>

<style lang="scss">
.vue-form-generator .inline-input-group-wrapper-detail {
	.form-group.error {
		position: relative;
		.errors {
			position: absolute;
			left: 100%;
			width: fit-content;
		}
	}

	.field-wrap input[type='submit'] {
		font-size: 0.75rem;
		background-color: #20a8d8 !important;
		border-color: #20a8d8 !important;
	}
	@include ig-first-item(43%, 65%, 92%);
	@include ig-second-item(42%, 10px);
}

.translation-form .field-html-text-editor > .field-wrap {
	width: 70% !important;
}
</style>
