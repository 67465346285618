var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          showActionDelete: false,
          tooltipMsg: "manualfordictio3324",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v("\n\t\t" + _vm._s(_vm.$t("detailofword")) + "\n\t\t"),
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.$t("id")) + " " + _vm._s(_vm.model.DtextId)
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "formActions",
              fn: function () {
                return [
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("savechanges2578"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.saveModel({ closeOnSuccess: false })
                      },
                    },
                  }),
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("savechanges"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.saveModel({ closeOnSuccess: true })
                      },
                    },
                  }),
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "times",
                      title: _vm.$t("savechangesandu"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.saveModelAndRefresh()
                      },
                    },
                  }),
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-primary",
                      icon: "undo",
                      title: _vm.$t("cancel2168"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.redirectToTable()
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1469199171
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }