import vueFormGenerator from 'vue-form-generator';
import { formatter } from '../../services/helpers/formatter';

export const model = {
			DtextId: 0,
			DtgroupId: null,
			DtextDescription: null,
			DtextValueSk: null,
			DtextValueCz: null,
			DtextValueEn: null,
			DtextValueHu: null,
			DtextValuePl: null,
			DtextValueDe: null,
			DtextValueRu: null,
			DtextExtendedEditor: false,
			DtextTypeId: null,
			Created: null,
			IsChecked: false,
			ReadyToDelete: false,
			Code: null,
		};

export const fields = [
			{
				fields: [
					{
						type: 'select',
						model: 'DtgroupId',
						required: true,
						validator: vueFormGenerator.validators.integer,
						values: [],
						styleClasses: 'half-width',
						selectOptions: {
							noneSelectedText: '',
							value: 'DtgroupId',
							name: 'DtgroupName',
						},
						i18n: {
							label: 'group2671',
							selectOptions: {
								noneSelectedText: 'select',
							},
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'DtextDescription',
						max: 599,
						required: true,
						validator: vueFormGenerator.validators.string,
						i18n: {
							label: 'description3232',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'DtextValueSk',
						min: 1,
						required: true,
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === false;
						},
						i18n: {
							label: 'namesk2556',
						},
					},
					{
						type: 'html-text-editor',
						model: 'DtextValueSk',
						required: true,
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === true;
						},
						i18n: {
							label: 'namesk2556',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'DtextValueCz',
						min: 1,
						required: true,
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === false;
						},
						i18n: {
							label: 'namecz2558',
						},
					},
					{
						type: 'html-text-editor',
						model: 'DtextValueCz',
						required: true,
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === true;
						},
						i18n: {
							label: 'namecz2558',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'DtextValuePl',
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === false;
						},
						i18n: {
							label: 'namepl2561',
						},
					},
					{
						type: 'html-text-editor',
						model: 'DtextValuePl',
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === true;
						},
						i18n: {
							label: 'namepl2561',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'DtextValueEn',
						min: 1,
						required: true,
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === false;
						},
						i18n: {
							label: 'nameen2564',
						},
					},
					{
						type: 'html-text-editor',
						model: 'DtextValueEn',
						required: true,
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === true;
						},
						i18n: {
							label: 'nameen2564',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'DtextValueDe',
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === false;
						},
						i18n: {
							label: 'namede2562',
						},
					},
					{
						type: 'html-text-editor',
						model: 'DtextValueDe',
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === true;
						},
						i18n: {
							label: 'namede2562',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'DtextValueHu',
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === false;
						},
						i18n: {
							label: 'namehu2566',
						},
					},
					{
						type: 'html-text-editor',
						model: 'DtextValueHu',
						validator: vueFormGenerator.validators.string,
						visible(model) {
							return model && model.DtextExtendedEditor === true;
						},
						i18n: {
							label: 'namehu2566',
						},
					},
					{
						type: 'select',
						required: true,
						model: 'DtextTypeId',
						validator: vueFormGenerator.validators.integer,
						values: [],
						styleClasses: 'half-width',
						selectOptions: {
							noneSelectedText: '',
						},
						i18n: {
							label: 'typeofword',
							selectOptions: {
								noneSelectedText: 'select',
							},
						},
					},
				],
			},
			{
				styleClasses: 'inline-input-group-wrapper-detail',
				fields: [
					{
						type: 'input',
						inputType: 'text',
						model: 'Code',
						min: 1,
						disabled: true,
						required: false,
						validator: vueFormGenerator.validators.string,
						styleClasses: 'first-input',
						i18n: {
							label: 'generatedcode',
						},
					},
					{
						type: 'submit',
						icon: 'eye',
						model: 'ResetCode',
						value: '',
						styleClasses: 'second-input',
						i18n: {
							buttonText: 'resetcode',
						},
						onSubmit: null,
					},
				],
			},
			{
				fields: [
					{
						type: 'switch',
						model: 'DtextExtendedEditor',
						i18n: {
							label: 'htmleditor3234',
							textOn: 'yes2220',
							textOff: 'no2221',
						},
					},
					{
						type: 'switch',
						model: 'ReadyToDelete',
						i18n: {
							label: 'todelete3236',
							textOn: 'yes2220',
							textOff: 'no2221',
						},
					},
					{
						type: 'switch',
						model: 'IsChecked',
						i18n: {
							label: 'checked:',
							textOn: 'yes2220',
							textOff: 'no2221',
						},
					},
					{
						type: 'label',
						model: 'Created',
						disabled: true,
						get: function(model) {
							return model && model.Created ? formatter.dateTimeToString(model.Created) : '';
						},
						visible(model) {
							return model && model.Created;
						},
						i18n: {
							label: 'created:',
						},
					},
				],
			},
		];
